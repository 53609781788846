import { HttpClient } from '@angular/common/http'
import { inject, Injectable } from '@angular/core'
import { LogService } from '../../services/log.service'
import { ConfigService } from '../../services/config.service'

@Injectable({ providedIn: 'root' })
export class StageService {
   private readonly _cn = this.constructor.name
   private readonly _httpClient = inject(HttpClient)
   private readonly _logService = inject(LogService)
   private readonly _configService = inject(ConfigService)

   sendFeedback(text: string) {
      this._logService.info(this._cn, this.sendFeedback.name, this._logService.START)
      this._logService.debug(this._cn, this.sendFeedback.name, 'feedback:', text)
      return this._httpClient.post(`${this._configService.apiUrl}/feedback`, { text })
   }
}
