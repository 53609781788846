import { HttpErrorResponse } from '@angular/common/http'
import { Injectable, inject } from '@angular/core'
import { Actions, createEffect, ofType } from '@ngrx/effects'
import { Store, select } from '@ngrx/store'
import { NotificationType } from 'angular2-notifications'
import { of } from 'rxjs'
import { catchError, map, switchMap, tap, withLatestFrom } from 'rxjs/operators'
import { NotificationService } from '../../services/notification.service'
import { AppState } from '../app.state'
import * as StageActions from './stage.actions'
import { selectMenuOpen } from './stage.selectors'
import { StageService } from './stage.service'

@Injectable()
export class StageEffects {
   private _actions$ = inject(Actions)
   private _store = inject(Store<AppState>)
   private _stageService = inject(StageService)
   private _notificationService = inject(NotificationService)

   toggleMenu$ = createEffect(() =>
      this._actions$.pipe(
         ofType(StageActions.toggleMenu),
         withLatestFrom(this._store.pipe(select(selectMenuOpen))),
         switchMap(([action, isMenuOpen]) => {
            return of(isMenuOpen ? StageActions.closeMenu() : StageActions.openMenu())
         })
      )
   )

   sendFeedback$ = createEffect(() =>
      this._actions$.pipe(
         ofType(StageActions.sendFeedback),
         switchMap((action) =>
            this._stageService.sendFeedback(action.request).pipe(
               tap(console.log),
               map(() => {
                  this._notificationService.notify(NotificationType.Success)
                  return StageActions.sendFeedbackSuccess()
               }),
               catchError((error: HttpErrorResponse) => {
                  return of(StageActions.sendFeedbackFailure({ error: error }))
               })
            )
         )
      )
   )

   /* Notification error message */
   private _actionsForNotificationMessage = [StageActions.sendFeedbackFailure]

   listenAllFailureAction$ = createEffect(
      () =>
         this._actions$.pipe(
            ofType(...this._actionsForNotificationMessage),
            map((errorResponse) =>
               this._notificationService.notify(NotificationType.Error, undefined, `${errorResponse.error.status} - ${errorResponse.error.statusText}`)
            )
         ),
      { dispatch: false }
   )
}
