import { HttpErrorResponse } from '@angular/common/http'
import { Injectable, inject } from '@angular/core'
import { Actions, createEffect, ofType } from '@ngrx/effects'
import { Store, select } from '@ngrx/store'
import { NotificationType } from 'angular2-notifications'
import { of } from 'rxjs'
import { catchError, delay, map, switchMap, tap, withLatestFrom } from 'rxjs/operators'
import { NotificationService } from '../../services/notification.service'
import { UtilityService } from '../../services/utility.service'
import { AppState } from '../app.state'
import * as FLinkingToolActions from './f-linking-tool.actions'
import { selectGVOSearchOpen } from './f-linking-tool.selectors'
import { FLinkingToolService } from './f-linking-tool.service'
import { LogService } from '../../services/log.service'

@Injectable()
export class FLinkingToolEffects {
   private _cn = this.constructor.name
   private _actions$ = inject(Actions)
   private _logService = inject(LogService)
   private _store = inject(Store<AppState>)
   private _utilityService = inject(UtilityService)
   private _linkingToolService = inject(FLinkingToolService)
   private _notificationService = inject(NotificationService)

   /* Grant vs orgranisation */
   toggleGrantsVsOrgSearch$ = createEffect(() =>
      this._actions$.pipe(
         ofType(FLinkingToolActions.toggleGVOSearch),
         withLatestFrom(this._store.pipe(select(selectGVOSearchOpen))),
         switchMap(([action, isSearchOpen]) => {
            return of(isSearchOpen ? FLinkingToolActions.closeGVOSearch() : FLinkingToolActions.openGVOSearch())
         })
      )
   )

   searchGVOData$ = createEffect(() =>
      this._actions$.pipe(
         ofType(FLinkingToolActions.searchGVOData),
         switchMap((action) =>
            this._linkingToolService.searchGVOData(action.searchFields).pipe(
               tap((res) => this._logService.debug(this._cn, 'searchGVOData effect', 'response:', res)),
               map((gvoItems) => FLinkingToolActions.searchGVODataSuccess({ data: gvoItems })),
               catchError((error: HttpErrorResponse) => {
                  return of(FLinkingToolActions.searchGVODataFailure({ error: error }))
               })
            )
         )
      )
   )

   downloadhGVOData$ = createEffect(() =>
      this._actions$.pipe(
         ofType(FLinkingToolActions.downloadGVOData),
         switchMap((action) =>
            this._linkingToolService.downloadGVOData(action.searchFields).pipe(
               tap((res) => this._logService.debug(this._cn, 'downloadhGVOData effect', 'response:', res)),
               map((response) => {
                  if (response.body) {
                     this._utilityService.downloadFile(response)
                     return FLinkingToolActions.downloadGVODataSuccess()
                  } else {
                     return FLinkingToolActions.downloadGVODataFailure({ error: new HttpErrorResponse({ error: '0 - No blob in response to download' }) })
                  }
               }),

               catchError((error: HttpErrorResponse) => {
                  return of(FLinkingToolActions.downloadGVODataFailure({ error: error }))
               })
            )
         )
      )
   )

   addGVOComment$ = createEffect(() =>
      this._actions$.pipe(
         ofType(FLinkingToolActions.addGVOComment),
         switchMap((action) =>
            this._linkingToolService.addGVOComment(action.request).pipe(
               tap((res) => this._logService.debug(this._cn, 'addGVOComment effect', 'response:', res)),
               map(() => FLinkingToolActions.addGVOCommentSuccess()),
               catchError((error: HttpErrorResponse) => {
                  return of(FLinkingToolActions.addGVOCommentFailure({ error: error }))
               })
            )
         )
      )
   )

   deleteGVOComment$ = createEffect(() =>
      this._actions$.pipe(
         ofType(FLinkingToolActions.deleteGVOComment),
         switchMap((action) =>
            this._linkingToolService.deleteGVOComment(action.request).pipe(
               tap((res) => this._logService.debug(this._cn, 'deleteGVOComment effect', 'response:', res)),
               map(() => FLinkingToolActions.deleteGVOCommentSuccess()),
               catchError((error: HttpErrorResponse) => {
                  return of(FLinkingToolActions.deleteGVOCommentFailure({ error: error }))
               })
            )
         )
      )
   )

   addGVOLeadingEntity$ = createEffect(() =>
      this._actions$.pipe(
         ofType(FLinkingToolActions.addGVOLeadingEntity),
         switchMap((action) =>
            this._linkingToolService.addGVOLeadingEntity(action.request).pipe(
               tap((res) => this._logService.debug(this._cn, 'addGVOLeadingEntity effect', 'response:', res)),
               map(() => FLinkingToolActions.addGVOLeadingEntitySuccess()),
               catchError((error: HttpErrorResponse) => {
                  return of(FLinkingToolActions.addGVOLeadingEntityFailure({ error: error }))
               })
            )
         )
      )
   )

   deleteGVOLeadingEntity$ = createEffect(() =>
      this._actions$.pipe(
         ofType(FLinkingToolActions.deleteGVOLeadingEntity),
         switchMap((action) =>
            this._linkingToolService.deleteGVOLeadingEntity(action.request).pipe(
               tap((res) => this._logService.debug(this._cn, 'deleteGVOLeadingEntity effect', 'response:', res)),
               map(() => FLinkingToolActions.deleteGVOLeadingEntitySuccess()),
               catchError((error: HttpErrorResponse) => {
                  return of(FLinkingToolActions.deleteGVOLeadingEntityFailure({ error: error }))
               })
            )
         )
      )
   )

   addGVOPartner$ = createEffect(() =>
      this._actions$.pipe(
         ofType(FLinkingToolActions.addGVOPartner),
         switchMap((action) =>
            this._linkingToolService.addGVOPartner(action.request).pipe(
               tap((res) => this._logService.debug(this._cn, 'addGVOPartner effect', 'response:', res)),
               map(() => FLinkingToolActions.addGVOPartnerSuccess()),
               catchError((error: HttpErrorResponse) => {
                  return of(FLinkingToolActions.addGVOPartnerFailure({ error: error }))
               })
            )
         )
      )
   )

   deleteGVOPartner$ = createEffect(() =>
      this._actions$.pipe(
         ofType(FLinkingToolActions.deleteGVOPartner),
         switchMap((action) =>
            this._linkingToolService.deleteGVOPartner(action.request).pipe(
               tap((res) => this._logService.debug(this._cn, 'deleteGVOPartner effect', 'response:', res)),
               map(() => FLinkingToolActions.deleteGVOPartnerSuccess()),
               catchError((error: HttpErrorResponse) => {
                  return of(FLinkingToolActions.deleteGVOPartnerFailure({ error: error }))
               })
            )
         )
      )
   )

   addGVOResponsible$ = createEffect(() =>
      this._actions$.pipe(
         ofType(FLinkingToolActions.addGVOResponsible),
         switchMap((action) =>
            this._linkingToolService.addGVOResponsible(action.request).pipe(
               tap((res) => this._logService.debug(this._cn, 'addGVOResponsible effect', 'response:', res)),
               map(() => FLinkingToolActions.addGVOResponsibleSuccess()),
               catchError((error: HttpErrorResponse) => {
                  return of(FLinkingToolActions.addGVOResponsibleFailure({ error: error }))
               })
            )
         )
      )
   )

   addGVOPNOResponsible$ = createEffect(() =>
      this._actions$.pipe(
         ofType(FLinkingToolActions.addGVOPNOResponsible),
         switchMap((action) =>
            this._linkingToolService.addGVOPNOResponsible(action.matchmakingId).pipe(
               tap((res) => this._logService.debug(this._cn, 'addGVOPNOResponsible effect', 'response:', res)),
               map(() => FLinkingToolActions.addGVOPNOResponsibleSuccess()),
               catchError((error: HttpErrorResponse) => {
                  return of(FLinkingToolActions.addGVOPNOResponsibleFailure({ error: error }))
               })
            )
         )
      )
   )

   deleteGVOResponsible$ = createEffect(() =>
      this._actions$.pipe(
         ofType(FLinkingToolActions.deleteGVOResponsible),
         switchMap((action) =>
            this._linkingToolService.deleteGVOResponsible(action.request).pipe(
               tap((res) => this._logService.debug(this._cn, 'deleteGVOResponsible effect', 'response:', res)),
               map(() => FLinkingToolActions.deleteGVOResponsibleSuccess()),
               catchError((error: HttpErrorResponse) => {
                  return of(FLinkingToolActions.deleteGVOResponsibleFailure({ error: error }))
               })
            )
         )
      )
   )

   contactGVOResponsible$ = createEffect(() =>
      this._actions$.pipe(
         ofType(FLinkingToolActions.contactGVOResponsible),
         switchMap((action) =>
            this._linkingToolService.contactGVOResponsible(action.grantId, action.requst).pipe(
               tap((res) => this._logService.debug(this._cn, 'contactGVOResponsible effect', 'response:', res)),
               map(() => {
                  return FLinkingToolActions.contactGVOResponsibleSuccess()
               }),
               catchError((error: HttpErrorResponse) => {
                  return of(FLinkingToolActions.contactGVOResponsibleFailure({ error: error }))
               })
            )
         )
      )
   )

   /* Manage proposed ideas */
   getMPIData$ = createEffect(() =>
      this._actions$.pipe(
         ofType(FLinkingToolActions.getMPIData),
         switchMap((action) =>
            this._linkingToolService.searchMPIData(action.request).pipe(
               tap((res) => this._logService.debug(this._cn, 'getMPIData effect', 'response:', res)),
               map((mPiItems) => FLinkingToolActions.getMPIDataSuccess({ data: mPiItems })),
               catchError((error: HttpErrorResponse) => {
                  return of(FLinkingToolActions.getMPIDataFailure({ error: error }))
               })
            )
         )
      )
   )

   markMPIDataAsProcessed$ = createEffect(() =>
      this._actions$.pipe(
         ofType(FLinkingToolActions.markMPIDataAsProcessed),
         switchMap((action) =>
            this._linkingToolService.markMPIDataAsProcessed(action.id).pipe(
               tap((res) => this._logService.debug(this._cn, 'markMPIDataAsProcessed effect', 'response:', res)),
               map(() => {
                  return FLinkingToolActions.markMPIDataAsProcessedSuccess()
               }),
               catchError((error: HttpErrorResponse) => {
                  return of(FLinkingToolActions.markMPIDataAsProcessedFailure({ error: error }))
               })
            )
         )
      )
   )

   deleteMPIData$ = createEffect(() =>
      this._actions$.pipe(
         ofType(FLinkingToolActions.deleteMPIData),
         switchMap((action) =>
            this._linkingToolService.deleteMPIData(action.id).pipe(
               tap((res) => this._logService.debug(this._cn, 'deleteMPIData effect', 'response:', res)),
               map(() => FLinkingToolActions.deleteMPIDataSuccess()),
               catchError((error: HttpErrorResponse) => {
                  return of(FLinkingToolActions.deleteMPIDataFailure({ error: error }))
               })
            )
         )
      )
   )

   /* Projects seeking partners */
   getPSPData$ = createEffect(() =>
      this._actions$.pipe(
         ofType(FLinkingToolActions.getPSPData),
         switchMap((action) =>
            this._linkingToolService.getPSPData(action.request).pipe(
               tap((res) => this._logService.debug(this._cn, 'getPSPData effect', 'response:', res)),
               map((pSPItems) => FLinkingToolActions.getPSPDataSuccess({ data: pSPItems })),
               catchError((error: HttpErrorResponse) => {
                  return of(FLinkingToolActions.getPSPDataFailure({ error: error }))
               })
            )
         )
      )
   )

   /* Notification error message */
   private _actionsForErrorNotificationMessage = [
      FLinkingToolActions.searchGVODataFailure,
      FLinkingToolActions.downloadGVODataFailure,
      FLinkingToolActions.getMPIDataFailure,
      FLinkingToolActions.getPSPDataFailure,
      FLinkingToolActions.addGVOCommentFailure,
      FLinkingToolActions.deleteGVOCommentFailure,
      FLinkingToolActions.addGVOLeadingEntityFailure,
      FLinkingToolActions.deleteGVOLeadingEntityFailure,
      FLinkingToolActions.addGVOPartnerFailure,
      FLinkingToolActions.deleteGVOPartnerFailure,
      FLinkingToolActions.addGVOResponsibleFailure,
      FLinkingToolActions.deleteGVOResponsibleFailure,
      FLinkingToolActions.addGVOPNOResponsibleFailure,
      FLinkingToolActions.contactGVOResponsibleFailure,
      FLinkingToolActions.markMPIDataAsProcessedFailure,
      FLinkingToolActions.deleteMPIDataFailure,
      FLinkingToolActions.getPSPDataFailure,
   ]

   listenAllFailureAction$ = createEffect(
      () =>
         this._actions$.pipe(
            ofType(...this._actionsForErrorNotificationMessage),
            map((errorResponse) =>
               this._notificationService.notify(NotificationType.Error, undefined, `${errorResponse.error.status} - ${errorResponse.error.statusText}`)
            )
         ),
      { dispatch: false }
   )

   /* Notification success message */
   private _actionsForSuccessNotificationMessage = [
      FLinkingToolActions.contactGVOResponsibleSuccess,
      FLinkingToolActions.markMPIDataAsProcessedSuccess,
      FLinkingToolActions.deleteMPIDataSuccess,
   ]

   listenAllSuccessAction$ = createEffect(
      () =>
         this._actions$.pipe(
            ofType(...this._actionsForSuccessNotificationMessage),
            map(() => this._notificationService.notify(NotificationType.Success))
         ),
      { dispatch: false }
   )
}
