import { Injectable, OnDestroy, inject } from '@angular/core'
import { DialogService, DynamicDialogRef } from 'primeng/dynamicdialog'
import { LogService } from '../../services/log.service'
import { SpinnerComponent } from './spinner.component'

@Injectable({ providedIn: 'root' })
export class SpinnerService implements OnDestroy {
   private _cn = this.constructor.name
   private _logService = inject(LogService)
   private _dialogService = inject(DialogService)

   private spinnerDialogRef = inject(DynamicDialogRef<SpinnerComponent>)

   public showSpinner() {
      this.spinnerDialogRef = this._dialogService.open(SpinnerComponent, {
         modal: false,
         closable: false,
         showHeader: false,
         focusOnShow: false,
         styleClass: 'shadow-none',
      })
   }

   public closeSpinner() {
      this.spinnerDialogRef?.close()
   }

   ngOnDestroy(): void {
      this.closeSpinner()
   }
}
