{
   "name": "fe-linking-tool",
   "version": "0.0.0",
   "scripts": {
      "start": "env NG_BUILD_MANGLE=false ng serve --open",
      "build": "env NG_BUILD_MANGLE=false ng build --output-hashing=all --base-href=/",
      "format-code": "prettier --write .",
      "format-code:check": "prettier --check .",
      "prepare": "husky"
   },
   "lint-staged": {
      "**/*": "prettier --write --ignore-unknown"
   },
   "private": true,
   "dependencies": {
      "@angular/animations": "^18.0.0",
      "@angular/cdk": "^18.0.0",
      "@angular/common": "^18.0.0",
      "@angular/compiler": "^18.0.0",
      "@angular/core": "^18.0.0",
      "@angular/elements": "^18.0.0",
      "@angular/forms": "^18.0.0",
      "@angular/platform-browser": "^18.0.0",
      "@angular/platform-browser-dynamic": "^18.0.0",
      "@angular/router": "^18.0.0",
      "@ngrx/effects": "^18.0.0",
      "@ngrx/router-store": "^18.0.0",
      "@ngrx/store": "^18.0.0",
      "@ngx-translate/core": "^15.0.0",
      "@ngx-translate/http-loader": "^8.0.0",
      "angular2-notifications": "^16.0.1",
      "ng-inline-svg-2": "^15.0.1",
      "primeicons": "^7.0.0",
      "primeng": "^17.18.2",
      "rxjs": "~7.8.1",
      "tslib": "^2.6.3",
      "zone.js": "~0.14.7",
      "keycloak-angular": "^16.0.0",
      "keycloak-js": "^25.0.0"
   },
   "devDependencies": {
      "@angular-devkit/build-angular": "^18.0.0",
      "@angular/cli": "^18.0.0",
      "@angular/compiler-cli": "^18.0.0",
      "@ngrx/store-devtools": "^18.0.0",
      "@types/jasmine": "~5.1.0",
      "autoprefixer": "^10.4.19",
      "husky": "^9.0.11",
      "jasmine-core": "~5.1.0",
      "karma": "~6.4.0",
      "karma-chrome-launcher": "~3.2.0",
      "karma-coverage": "~2.2.0",
      "karma-jasmine": "~5.1.0",
      "karma-jasmine-html-reporter": "~2.1.0",
      "lint-staged": "^15.2.7",
      "postcss": "^8.4.38",
      "prettier": "^3.3.2",
      "tailwindcss": "^3.4.4",
      "typescript": "~5.4.5"
   }
}
