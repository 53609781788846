import { Routes } from '@angular/router'
import { AuthenticationService } from './shared/services/authentication.service'
import { ApplicationRoutesEnum } from './shared/models/enums/application-routes.enum'

export const routes: Routes = [
   { path: '', redirectTo: ApplicationRoutesEnum.MAIN_PAGE, pathMatch: 'full' },
   {
      path: ApplicationRoutesEnum.MAIN_PAGE,
      canActivate: [AuthenticationService],
      loadChildren: () => import('./core/components/pages/private/main-page/main-page.routes').then((m) => m.MAIN_PAGE_ROUTES),
   },
]
