import { CommonModule } from '@angular/common'
import { Component } from '@angular/core'

@Component({
   selector: 'app-spinner',
   standalone: true,
   imports: [CommonModule],
   template: `<div class="w-[150px] h-[150px] bg-pno-spinner"></div>`,
})
export class SpinnerComponent {}
